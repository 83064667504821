import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/service/web.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public Url:string='https://paginas.virextec.com/archivos/'

  public webDatos:any;
  public productDatos:any;
  public seccionDatos:any;
  public seccionSola:any; 

  constructor(private webService: WebService) { }

  ngOnInit(): void {

    let top = document.getElementById('homePage');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let seccion=window.location.hash.split('/')[1];

    let scope=this;
    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];

     let miPromesa = new Promise(function(resolve:any, reject:any){
       resolve();
     });

     miPromesa.then(function(){
       scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{

        console.log('MI DATA ES...', data)
        scope.webDatos=data.web[0];
        console.log('web', scope.webDatos);
        
        scope.productDatos=data.proAll;
        console.log('productos pipi', scope.productDatos);

        scope.seccionDatos=data.sections;
        console.log('secciones', scope.seccionDatos);


        //localStorage.removeItem('myProducts');         
        //localStorage.removeItem('refresh'); 
        //localStorage.setItem('myProducts', JSON.stringify(data.productos))

        let newObj = data.sections.filter((x)=>{            
          return x.Url.indexOf(seccion) != -1 ? x:null
        });          

        scope.seccionSola=newObj[0];        
        console.log('seccion sola', scope.seccionSola);
       })
     })

  }

}
