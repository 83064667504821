import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebService } from 'src/app/service/web.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  
  public Url:string='https://paginas.virextec.com/archivos/'

  aFormGroup: FormGroup;
  siteKey:string="6LdD78caAAAAABTWk5xb_3e8NClqBnERDZW6agwP";
  name: FormControl = new FormControl("",[Validators.required]);
  email: FormControl = new FormControl("", [Validators.required]);
  subject: FormControl = new FormControl("", [Validators.required]);
  message: FormControl = new FormControl("", [Validators.required]);
  recaptcha: FormControl = new FormControl ('', [Validators.required]);

  public webDatos:any;
  public seccionSola:any; 

  constructor(private webService: WebService, private http:HttpClient, private formBuilder:FormBuilder) { 
    this.aFormGroup = this.formBuilder.group({
      name:this.name,
      email: this.email,
      subject: this.subject,
      message: this.subject,
      recaptcha:this.recaptcha
    });
  }

  ngOnInit(): void {
    let top = document.getElementById('topPage');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let seccion=window.location.hash.split('/')[1];

    let scope=this;
    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];

    let miPromesa = new Promise(function(resolve:any, reject:any){
      resolve();
    });

    miPromesa.then(function(){
      scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{
       scope.webDatos=data.web[0];
       console.log('web', scope.webDatos);
       //localStorage.removeItem('myProducts');         
       //localStorage.removeItem('refresh'); 
       //localStorage.setItem('myProducts', JSON.stringify(data.productos))

       let newObj = data.sections.filter((x)=>{            
         return x.Url.indexOf(seccion) != -1 ? x:null
       });          

       scope.seccionSola=newObj[0];        
       console.log('seccion sola', scope.seccionSola);
      })
    })

  }

  public msnSend:string='';
  public statusMsn:number=0;

  sendMail(){

    let nameOrigin:string = this.webDatos.Tit;
    let emailOrigin:string = this.webDatos.WebSitEma1;

    if(this.aFormGroup.status == "VALID"){
      this.aFormGroup.disable();

      var formData:any = new FormData();

      formData.append("name", this.aFormGroup.get("name").value),
      formData.append("email", this.aFormGroup.get("email").value);
      formData.append("subject", this.aFormGroup.get("subject").value);
      formData.append("message", this.aFormGroup.get("message").value);
      formData.append("nameOrigin", nameOrigin);
      formData.append("emailOrigin", emailOrigin);

      this.http.post('https://paginas.virextec.com/servidor/MyMailService.php', formData).subscribe((response)=>{                    
        // choose the response message
        if (response == "Succes") {
          this.statusMsn = 1;
          this.msnSend="SU MENSAJE FUE ENVIADO SATISFACTORIAMENTE";      
          //this.responseMessage = "Thanks for the message! I'll get back to you soon!";            
        } else {
          console.log("ERROR EN EL ENVIO DE SU MENSAJE" , response);
          //this.responseMessage = "Oops! Something went wrong... Reload the page and try again.";
        }
      },(error)=>{
        console.log("ERROR EN EL ENVIO CON EL SERVIDOR" , error);
      })
    }else{
      if(this.statusMsn == 1){
        this.statusMsn=3;
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }if(this.statusMsn == 3){
        this.msnSend="Ya se envio anteriormente este mensaje...";
      }else{
        this.statusMsn=2
        this.msnSend="El formulario no fue completado...";
      }
      
    }
  }

}
