import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  constructor(private http: HttpClient) { }

  public getDatos(acction,dom,org,web,proTip,proMat,proMar,proFam,proBus):Observable<any>{

    return this.http.get<any>('https://paginas.virextec.com/servidor/MyWebSiteService.php',{
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      params: {acction:acction,dom:dom,org:org,web:web,proTip:proTip,proMat:proMat,proMar:proMar,proFam:proFam,proBus:proBus}    
    });
  }

  dom:string="maisondusoleil.com.pe";
  org:number=5;
  web:number=4;

  public getEmpresa(){
    let data:any =[this.dom, this.web, this.org];
    return data;    
  }


}
