<div id="homePage" class="hero-wrap" style="background-image: url('{{Url}}{{seccionDatos?.Img}}');">
<div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text d-flex align-itemd-end justify-content-center">
        <!--<div class="col-md-9 ftco-animate text-center d-flex align-items-end justify-content-center">-->
        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
            <div class="text">
                <p class="breadcrumbs mb-2"><span class="mr-2"><a routerLink="/">Inicio</a></span> <span>Acerca de</span></p>
                <h1 class="mb-4 bread">{{seccionDatos?.Tit}}</h1>
            </div>
        </div>
        </div>
    </div>
</div>

<section class="ftco-section">
    <div class="container">        
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-12"> 
                        <owl-carousel-o [options]="owlOptions">
                            <ng-template carouselSlide>
                                <div class="slide">
                                <img src="{{Url}}{{producDatos?.ProDirImg2}}" alt="Content 1">
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="slide">
                                <img src="{{Url}}{{producDatos?.ProDirImg3}}" alt="Content 2">
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="slide">
                                <img src="{{Url}}{{producDatos?.ProDirImg4}}" alt="Content 3">
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="slide">
                                <img src="{{Url}}{{producDatos?.ProDirImg1}}" alt="Content 4">
                                </div>
                            </ng-template>                      
                        </owl-carousel-o>
                    </div>                   
                    
                    <!--<div class="col-md-12 room-single mt-4 mb-5 ftco-animate">-->
                    <div class="col-md-12 room-single mt-4 mb-5">
                        <h2 class="mb-4">{{producDatos?.ProNom}} <span>- (10 Available rooms)</span></h2>
                        <p>{{producDatos?.ProDes}}</p>
                        <div class="d-md-flex">
                            <ul class="list">
                                <li><span>Max:</span> {{producDatos?.ProMedMax}}</li>
                                <li><span>Tamaño:</span> {{producDatos?.ProMedMin}}</li>
                            </ul>
                            <!--<ul class="list ml-md-5">
                                <li><span>View:</span> Sea View</li>
                                <li><span>Bed:</span> 1</li>
                            </ul>-->
                        </div>                        
                    </div>

                    <!--<div class="col-md-12 room-single ftco-animate mb-5 mt-4">-->                
                    <div class="col-md-12 room-single mb-5 mt-4">
                        <h3 class="mb-4">Hacer un recorrido</h3>
                        <div class="block-16">
                            <figure>
                                <img src="{{Url}}{{producDatos?.ProDirImg1}}" alt="Image placeholder" class="img-fluid">
                                <a href="https://vimeo.com/45830194" class="play-button popup-vimeo"><span class="icon-play"></span></a>
                            </figure>
                        </div>
                    </div>
                </div>
            </div> 

            <!--<div class="col-lg-4 sidebar ftco-animate">-->
            <div class="col-lg-4 sidebar">
                <!--<div class="sidebar-box ftco-animate">-->              
                <div class="sidebar-box">
                    <div class="categories">
                        <h3>Servicios</h3>
                        <li *ngFor="let ser of serviceDatos"><a href="/servicios/{{ser?.SerID}}">{{ser?.SerNom}}</a></li>
                    </div>
                </div>

                <!--<div class="sidebar-box ftco-animate">-->
                <div class="sidebar-box">
                    <h3>Otras habitaciones</h3>
                    <div class="block-21 mb-4 d-flex" *ngFor="let sim of productSimilar">
                        <a class="blog-img mr-4" style="background-image: url({{Url}}{{sim?.ProDirImg1}});"></a>
                        <div class="text">
                            <h3 class="heading"><a (click)="reLoad()" routerLink="/habitaciones/{{sim?.ProID}}">{{sim?.ProNom}}</a></h3> 
                            <div class="meta">                            
                                <div><a (click)="reLoad()" routerLink="/habitaciones/{{sim?.ProID}}"><span class="icon-person"></span> {{sim?.ProMedMax}}</a></div>
                                <div><a routerLink="/contactenos"><span class="icon-chat"></span> contactenos</a></div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section> <!-- .section -->