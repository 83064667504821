<div id="topPage" class="hero-wrap" style="background-image: url('{{Url}}{{seccionSola?.Img}}');">
    <div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text d-flex align-itemd-end justify-content-center">
        <!--<div class="col-md-9 ftco-animate text-center d-flex align-items-end justify-content-center">-->
        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
            <div class="text">
                <p class="breadcrumbs mb-2"><span class="mr-2"><a routerLink="/">Inicio</a></span> <span>Acerca de</span></p>
                <h1 class="mb-4 bread">{{seccionSola?.Tit}}</h1>
            </div>
        </div>
        </div>
    </div>
</div>

<section class="ftco-section ftc-no-pb ftc-no-pt">
    <div class="container">
        <div class="row">
            <div class="col-md-5 p-md-5 img img-2 img-3 d-flex justify-content-center align-items-center" style="background-image: url('{{Url}}{{seccionSola?.Img}}');">
                <a href="https://vimeo.com/45830194" class="icon popup-vimeo d-flex justify-content-center align-items-center">
                    <span class="icon-play"></span>
                </a>
            </div>
            <div class="col-md-7 py-5 wrap-about pb-md-5">
                <div class="heading-section heading-section-wo-line pt-md-5 mb-5">
                    <div class="ml-md-0">
                        <span class="subheading">Bienvenidos al Hotel {{webDatos?.Tit}}</span>
                        <h2 class="mb-4">Bienvenida a nuestro hotel</h2>
                    </div>
                </div>
                <div class="pb-md-5">
                    <p>{{webDatos?.Des}}</p>
                    <ul class="ftco-social d-flex">
                        <li *ngFor="let red of redesDatos" class=""><a href="{{red?.RedLin}}" target="_blank"><span class="{{red?.RedIco}}"></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="ftco-section">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center animated fadeInUp">
        <!--<div class="col-md-7 heading-section text-center">-->
          <span class="subheading">Bienvenidos al Hotel {{webDatos?.Tit}}</span>
          <h2 class="mb-4">VIVE CON TODOS TUS SENTIDOS EN MAISON DU SOLEIL</h2>
        </div>
      </div>  
      <div class="row d-flex">
        <!--<div class="col-md pr-md-1 d-flex align-self-stretch ftco-animate">-->
          <div *ngFor=" let ser of serviciosDatos" class="col-md pr-md-1 d-flex align-self-stretch">
            <div class="media block-6 services py-4 d-block text-center">
              <div class="d-flex justify-content-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="{{ser?.SerIco}}"></span>
                </div>
              </div>
              <div class="media-body">
                <h3 class="heading mb-3">{{ser?.SerNom}}</h3>
              </div>
            </div>      
          </div>  
      </div>
    </div>
  </section>

  <!--<section class="ftco-section ftco-no-pt ftco-no-pb px-0">
    <div class="container-fluid px-0">
        <div class="row no-gutters justify-content-end">
            <div class="col-md-12">
                <div id="home" class="video-hero" style="height: 800px; background-image: url('{{Url}}{{webDatos?.WebSitLog}}'); background-size:cover; background-position: center center;">
                    <a class="player" data-property="{videoURL:'https://www.youtube.com/watch?v=ism1XqnZJEg',containment:'#home', showControls:false, autoPlay:true, loop:true, mute:true, startAt:0, opacity:1, quality:'default'}"></a>
                    <div class="container">
                        <div class="row justify-content-start d-flex align-items-end height-text ">
                            <div class="col-md-8">
                                <div class="text">
                                    <h1>We're Most Recommended Hotel</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->


<section class="instagram">
    <div class="container-fluid">
      <div class="row no-gutters justify-content-center pb-5">
        <!--<div class="col-md-7 text-center heading-section ftco-animate">-->
        <div class="col-md-7 text-center heading-section">
            <span class="subheading">Fotos</span>
          <h2><span>Instagram</span></h2>
        </div>
      </div>
      <div class="row no-gutters">
        <div *ngFor="let gal of galInsgram" class="col-sm-12 col-md">
          <a href="{{Url}}{{gal?.FotDir}}" class="insta-img image-popup" style="background-image: url({{Url}}{{gal?.FotDir}});">
            <div class="icon d-flex justify-content-center">
              <span class="icon-instagram align-self-center"></span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>

