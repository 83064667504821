<div id="topPage" class="hero-wrap" style="background-image: url('{{Url}}{{seccionSola?.Img}}');">
    <div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text d-flex align-itemd-end justify-content-center">
        <!--<div class="col-md-9 ftco-animate text-center d-flex align-items-end justify-content-center">-->
        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
            <div class="text">
                <p class="breadcrumbs mb-2"><span class="mr-2"><a routerLink="/">Inicio</a></span> <span>Acerca de</span></p>
                <h1 class="mb-4 bread">{{seccionSola?.Tit}}</h1>
            </div>
        </div>
        </div>
    </div>
</div>

<section class="ftco-section contact-section bg-light">
<div class="container">
    <div class="row d-flex mb-5 contact-info">
        <div class="col-md-12 mb-4">
            <h2 class="h3">Informacion Para Contactarnos</h2>
        </div>
        <div class="w-100"></div>
        <div class="col-md-4 d-flex">
            <div class="info bg-white p-4">
                <p><span><b>Dirección:</b></span></p>
                <p>{{webDatos?.WebSitDir1}}</p>
                <p>{{webDatos?.WebSitDir2}}</p>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="info bg-white p-4">
                <p><span><b>Telefonos:</b></span></p>
                <p>{{webDatos?.WebSitNum1}}</p>
                <p>{{webDatos?.WebSitNum2}}</p>
                <p>{{webDatos?.WebSitNum3}}</p>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="info bg-white p-4">
                <p><span><b>Email:</b></span></p>
                <p>{{webDatos?.WebSitEma1}}</p>
                <p>{{webDatos?.WebSitEma2}}</p>
            </div>
        </div>
    </div>
    <div class="row block-9">
        <div class="col-md-6 order-md-last d-flex">
            <form [formGroup]="aFormGroup" class="bg-white p-5 contact-form">
                <div class="form-group">
                    <input type="text" class="form-control" [formControl]="name" placeholder="Su nombre">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" [formControl]="email" placeholder="Su Email">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" [formControl]="subject" placeholder="Asunto">
                </div>
                <div class="form-group">
                    <textarea name="" id="" cols="30" rows="7" class="form-control" [formControl]="message" placeholder="Mensage"></textarea>
                </div>
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"></ngx-recaptcha2>

                <div *ngIf="statusMsn === 2" class="alert alert-warning col-md-11" >{{msnSend}}</div>
                <div *ngIf="statusMsn === 1" class="alert alert-success col-md-11" >{{msnSend}}</div>
                <div *ngIf="statusMsn === 3" class="alert alert-info col-md-11" >{{msnSend}}</div>

                <div class="form-group">
                    <input type="submit" value="Enviar" class="btn btn-primary py-3 px-5" (click)="sendMail()">
                </div>
            </form>        
        </div>
        <div class="col-md-6 d-flex">            
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4450.739766824269!2d-71.53400014816657!3d-16.392637354832292!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a502ca49cb9%3A0x4c12196af81dcc21!2sMaison%20du%20Soleil!5e0!3m2!1ses-419!2sbr!4v1701210589498!5m2!1ses-419!2sbr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1358.3240052973165!2d-71.65894655772333!3d-15.64922343747461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9169e14d5e5307fd%3A0xb4f2b400d666d2ab!2sSUMAQ%20WAYRA!5e0!3m2!1ses-419!2sbr!4v1621077858619!5m2!1ses-419!2sbr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
            <!--<div id="map" class="bg-white"></div>-->
        </div>
    </div>
</div>
</section>