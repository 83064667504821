<nav id="home" class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
  <div class="container">
    <a class="navbar-brand" href="/">{{webDatos?.Tit}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="oi oi-menu"></span> Menu
    </button> 

    <div class="collapse navbar-collapse" id="ftco-nav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngFor="let sec of seccionDatos"><a routerLink="{{sec?.Url}}" class="nav-link">{{sec?.Tit}}</a></li>
      </ul>
    </div>
  </div>
</nav>


<router-outlet></router-outlet>


<footer class="ftco-footer ftco-bg-dark ftco-section">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md">
        <div class="ftco-footer-widget mb-4">
          <h2 class="ftco-heading-2">{{webDatos?.Tit}}</h2>
          <p>{{webDatos?.Des}}</p>
          <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
            <li class="" *ngFor="let red of redesDatos"><a href="{{red?.RedLin}}" target="_blank"><span class="{{red?.RedIco}}"></span></a></li>
            <!--<li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>-->
          </ul>
        </div>
      </div>
      <div class="col-md">
        <div class="ftco-footer-widget mb-4 ml-md-5">
          <h2 class="ftco-heading-2">Enlaces Utiles</h2>
          <ul class="list-unstyled">
            <li *ngFor="let sec2 of seccionDatos"><a routerLink="{{sec2?.Url}}" class="py-2 d-block">{{sec2.Tit}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md">
        <div class="ftco-footer-widget mb-4">
          <h2 class="ftco-heading-2">DATOS PARA CONTACTARNOS</h2>
          <div class="block-23 mb-3">
            <ul>
              <li><span class="icon icon-map-marker"></span><span class="text">{{webDatos?.WebSitDir1}}</span></li>
              <li><a href="#"><span class="icon icon-phone"></span><span class="text">{{webDatos?.WebSitNum1}}</span></a></li>
              <li><a href="#"><span class="icon icon-envelope"></span><span class="text">{{webDatos?.WebSitEma1}}</span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
        Copyright &copy; All rights reserved | This template is made with by <a href="https://virextec.com" target="_blank">VirexTec</a>
<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
      </div>
    </div>
  </div>
</footer>

<!--<div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div>-->
 