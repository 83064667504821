<div id="homePage" class="hero-wrap" style="background-image: url('{{Url}}{{seccionSola?.Img}}');">
<div class="overlay"></div>
    <div class="container">
        <div class="row no-gutters slider-text d-flex align-itemd-end justify-content-center">
        <!--<div class="col-md-9 ftco-animate text-center d-flex align-items-end justify-content-center">-->
        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
            <div class="text">
                <p class="breadcrumbs mb-2"><span class="mr-2"><a routerLink="/">Inicio</a></span> <span>Acerca de</span></p>
                <h1 class="mb-4 bread">{{seccionSola?.Tit}}</h1>
            </div>
        </div>
        </div>
    </div>
</div>

<section class="ftco-section bg-light ftco-no-pb">
    <div class="container-fluid px-0">
        <div class="row no-gutters justify-content-center mb-5 pb-3">
            <!--<div class="col-md-7 heading-section text-center ftco-animate">-->
            <div class="col-md-7 heading-section text-center">
                <span class="subheading">{{webDatos?.Tit}}</span>
                <h2 class="mb-4">{{seccionSola?.Tit}} del Hotel {{webDatos?.Tit}}</h2>
            </div>
        </div>  
        <div class="row no-gutters">
            <div class="col-lg-6" *ngFor="let hab of productDatos">
                <div class="room-wrap d-md-flex">
                    <a routerLink="/habitaciones/{{hab?.ProID}}" class="img" style='background-image: url("{{Url}}{{hab?.ProDirImg1}}")'></a>
                    <div class="half left-arrow d-flex align-items-center">
                        <div class="text p-4 p-xl-5 text-center">
                            <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                            <p class="mb-0"><span class="price mr-1">$ {{hab?.ProPre}}0</span> <span class="per">Por Noche</span></p>
                            <h3 class="mb-3"><a routerLink="/habitaciones/{{hab?.ProID}}">{{hab?.ProNom}}</a></h3>
                            <p class="pt-1"><a routerLink="/habitaciones/{{hab?.ProID}}" class="btn-custom px-3 py-2">Ver Mas Detalles <span class="icon-long-arrow-right"></span></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>