<div class="hero" id="homePage">
  <div class="container-wrap d-flex justify-content-end align-items-end">
    <a href="https://www.youtube.com/watch?v=ism1XqnZJEg"
      class="icon-video popup-vimeo d-flex justify-content-center align-items-center">
      <span class="ion-ios-play play"></span>
    </a>
  </div>
  <section class="home-slider owl-carousel2">
    <owl-carousel-o [options]="owlOptions">
      <ng-container *ngFor="let data of sliderDatos">
        <ng-template carouselSlide [id]="data?.SliID">

          <div class="slider-item" style="background-image:url('{{Url}}{{data?.SliDirImg}}');">
            <div class="overlay"></div>
            <div class="container">
              <div class="row2 no-gutters2 slider-text align-items-center">
                <div class="col-md-8">
                  <div class="text mb-5 pb-5">
                    <a routerLink="{{data?.SliLin}}">
                      <h1 class="mb-3">{{data?.SliTex1}}</h1>
                    </a>
                    <h2>{{data?.SliTex2}}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>

  </section>
</div>

<section class="ftco-booking ftco-section ftco-no-pt ftco-no-pb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 pr-1 aside-stretch">
        <form action="#" class="booking-form">
          <div class="row">
            <div class="col-md d-flex py-md-4">
              <div class="form-group align-self-stretch d-flex align-items-end">
                <div class="wrap bg-white align-self-stretch py-3 px-4">
                  <label for="#">Fecha de ingreso</label>
                  <input type="date" class="form-control checkin_date" placeholder="Check-in date">
                </div>
              </div>
            </div>
            <div class="col-md d-flex py-md-4">
              <div class="form-group align-self-stretch d-flex align-items-end">
                <div class="wrap bg-white align-self-stretch py-3 px-4">
                  <label for="#">Fecha de salida</label>
                  <input type="date" class="form-control checkout_date" placeholder="Check-out date">
                </div>
              </div>
            </div>
            <div class="col-md d-flex py-md-4">
              <div class="form-group align-self-stretch d-flex align-items-end">
                <div class="wrap bg-white align-self-stretch py-3 px-4">
                  <label for="#">Habitacion</label>
                  <div class="form-field">
                    <div class="select-wrap">
                      <div class="icon"><span class="ion-ios-arrow-down"></span></div>
                      <select name="" id="" class="form-control">
                        <option *ngFor="let habit of productosDatos" value="">{{habit?.ProNom}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md d-flex">
              <div class="form-group d-flex align-self-stretch">
                <a class="btn btn-black py-5 py-md-3 px-4 align-self-stretch d-block"
                  (click)="reserCon()"><span>Consultar Reserva <small>El mejor precio garatizado</small></span></a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<section class="ftco-section ftco-no-pt ftco-no-pb ftco-services-wrap">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-md-3" *ngFor="let sec2 of listaSecciones">
        <a routerLink="{{sec2?.Url}}" class="services-wrap img align-items-end d-flex"
          style="background-image: url({{Url}}{{sec2?.Img}});">
          <div class="text text-center pb-2">
            <h3>{{sec2?.Tit}}</h3>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center mb-5 pb-3">
      <div class="col-md-7 heading-section text-center animated fadeInUp">
        <!--<div class="col-md-7 heading-section text-center">-->
        <span class="subheading">Bienvenidos al Hotel {{empresaDatos?.Tit}}</span>
        <h2 class="mb-4">VIVE CON TODOS TUS SENTIDOS EN MAISON DU SOLEIL</h2>
      </div>
    </div>
    <div class="row d-flex">
      <!--<div class="col-md pr-md-1 d-flex align-self-stretch ftco-animate">-->
      <div *ngFor=" let ser of serviciosDatos" class="col-md pr-md-1 d-flex align-self-stretch">
        <div class="media block-6 services py-4 d-block text-center">
          <div class="d-flex justify-content-center">
            <div class="icon d-flex align-items-center justify-content-center">
              <span class="{{ser?.SerIco}}"></span>
            </div>
          </div>
          <div class="media-body">
            <h3 class="heading mb-3">{{ser?.SerNom}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="ftco-section bg-light ftco-no-pb">
  <div class="container-fluid px-0">
    <div class="row no-gutters justify-content-center mb-5 pb-3">
      <!--<div class="col-md-7 heading-section text-center ftco-animate">-->
      <div class="col-md-7 heading-section text-center">
        <span class="subheading">{{empresaDatos?.Tit}}</span>
        <h2 class="mb-4">Habitaciones del Hotel {{empresaDatos?.Tit}}</h2>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-6">
        <div class="room-wrap">
          <div class="img d-flex align-items-center" style="background-image: url({{Url}}{{seccionSola?.Img}});">
            <div class="text text-center px-4 py-4">
              <h2>Bienvenidos al Hotel <a routerLink="/">{{empresaDatos?.Tit}}</a></h2>
              <p>VIVE CON TODOS TUS SENTIDOS EN MAISON DU SOLEIL</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" *ngFor="let hab of productosDatos">
        <div class="room-wrap d-md-flex">  
          <a routerLink="/habitaciones/{{hab?.ProID}}" class="img" style='background-image: url("{{Url}}{{hab?.ProDirImg1}}")'></a> 
          <div class="half left-arrow d-flex align-items-center">
            <div class="text p-4 p-xl-5 text-center">
              <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span
                  class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
              <p class="mb-0"><span class="price mr-1">$ {{hab?.ProPre}}0</span> <span class="per">Por Noche</span>
              </p>
              <h3 class="mb-3"><a routerLink="/habitaciones/{{hab?.ProID}}">{{hab?.ProNom}}</a></h3>
              <p class="pt-1"><a routerLink="/habitaciones/{{hab?.ProID}}" class="btn-custom px-3 py-2">Ver Mas Detalles
                  <span class="icon-long-arrow-right"></span></a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="instagram">
  <div class="container-fluid">
    <div class="row no-gutters justify-content-center pb-5">
      <!--<div class="col-md-7 text-center heading-section ftco-animate">-->
      <div class="col-md-7 text-center heading-section">
        <span class="subheading">Fotos</span>
        <h2><span>Instagram</span></h2>
      </div>
    </div>
    <div class="row no-gutters">
      <div *ngFor="let gal of galInsgram" class="col-sm-12 col-md">
        <a href="{{Url}}{{gal?.FotDir}}" class="insta-img image-popup"
          style="background-image: url({{Url}}{{gal?.FotDir}});">
          <div class="icon d-flex justify-content-center">
            <span class="icon-instagram align-self-center"></span>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>