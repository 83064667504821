import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/service/web.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit { 

  aFormGroup: FormGroup;
  siteKey:string="6LdD78caAAAAABTWk5xb_3e8NClqBnERDZW6agwP";

  public producDatos:any;
  public serviceDatos:any;
  public productSimilar:any;
  public seccionDatos:any;
  public redesDatos:any;
  public empresaDatos:any;

  public Url='https://paginas.virextec.com/archivos/';

  constructor(private webService: WebService, private formBuilder:FormBuilder) { }

  ngOnInit(): void {

    let top = document.getElementById('homePage');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    
    //this.refresh();
    //this.misScrip();
    //this.cartItemFunc()  
    
    let scope=this;

    let pathArray = window.location.hash.split('/');    
    let seccion =pathArray[1];
    let pro:number = Number(pathArray[2]);    
    
    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];

    let miPromesa = new Promise(function(resolve:any, reject:any){
      resolve();
    });

    miPromesa.then(function(){
      scope.webService.getDatos(0, dom, org, web,0,0,0,0, pro).subscribe((data)=>{

        console.log(data);        

        scope.producDatos=data.proBus[0];
        scope.redesDatos=data.redes;        
        scope.empresaDatos=data.web[0];

        let newObj0 = data.servicios.filter((value)=>{
          return value.SerTip.indexOf('general') != -1 ? value:null
        });
        scope.serviceDatos=newObj0;

        let newObj = data.sections.filter((value)=>{
          return value.Url.indexOf(seccion) != -1 ? value:null
        });
        scope.seccionDatos=newObj[0];

        let newObj2 = data.productos.filter((value)=>{
          return value.FamID.indexOf(data.proBus[0].FamID) != -1 ? value:null
        });   

        let newObj22 = newObj2.filter((value)=>{
          return value.ProID.indexOf(pro) == -1 ? value:null
        })
        scope.productSimilar=newObj22;
         
      })
      
    })     


  }

  reLoad(){
    let top = document.getElementById('home');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    function delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }

    (async () => {  
        await delay(100);
        window.location.reload();
    })();  
  }


  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&#8249', '&#8250;'],
    autoplay:true,
    autoplaySpeed:770,
    autoplayHoverPause:true,
    autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1 
      },
      480: {
        items: 1
      },
      767: {
        items: 2
      },
      1024: {
        items: 3
      }
    },
    nav: true
  }
}
