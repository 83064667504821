import { Component, OnInit } from '@angular/core';
import { WebService } from 'src/app/service/web.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public Url:string='https://paginas.virextec.com/archivos/'

  public webDatos:any;
  public seccionSola:any; 
  public redesDatos:any; 
  public serviciosDatos:any;
  public galInsgram:any;

  constructor(private webService: WebService) { }

  ngOnInit(): void {

    let top = document.getElementById('topPage');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let seccion=window.location.hash.split('/')[1];

    let scope=this;
    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];

    let miPromesa = new Promise(function(resolve:any, reject:any){
      resolve();
    });

    miPromesa.then(function(){
      scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{
       scope.webDatos=data.web[0];
       console.log('web', scope.webDatos);
       scope.redesDatos=data.redes;
       console.log('redes', scope.redesDatos);
       //localStorage.removeItem('myProducts');         
       //localStorage.removeItem('refresh'); 
       //localStorage.setItem('myProducts', JSON.stringify(data.productos))

       let newObj = data.sections.filter((x)=>{            
         return x.Url.indexOf(seccion) != -1 ? x:null
       });          

       scope.seccionSola=newObj[0];        
       console.log('seccion sola', scope.seccionSola);

       let newObj2 = data.servicios.filter((value)=>{
        return value.SerTip.indexOf('general') != -1 ? value:null
      });
      scope.serviciosDatos = newObj2;
      console.log('servicios', scope.serviciosDatos);

      let galIns = data.galeria[0].GalID;
      let newObj4 = data.fotos.filter((x)=>{
        return x.GalID.indexOf(galIns) != -1 ? x:null
      })

      scope.galInsgram = newObj4;
      console.log('Galeria instagram', scope.galInsgram);

      })
    })  


  }

}
