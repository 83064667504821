import { Component, OnInit } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { WebService } from 'src/app/service/web.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit { 

  constructor(private webService:WebService) { }

  public Url='https://paginas.virextec.com/archivos/';

  public empresaDatos:any;
  public redesDatos:any;
  public productosDatos:any;
  public serviciosDatos:any;  
  public sliderDatos:any;
  public seccionDatos:any;
  public listaSecciones:any;
  public listaSecciones2:any;
  public seccionSola:any;
  public galInsgram:any;

  public miPromesa = new Promise(function(resolve:any, reject:any){
    resolve();
  })

  

  ngOnInit(): void {

    let top = document.getElementById('homePage');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }

    let scope = this;

    let link = window.location;
    let seccion =link.pathname.split('/')[1];

    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];  

    scope.miPromesa.then(function(){

      scope.webService.getDatos(0, dom, org, web,0,0,0,0, 0).subscribe((data)=>{

        console.log('data', data);
        
        scope.empresaDatos = data.web[0];
        console.log('empresa', scope.empresaDatos);
        
        scope.redesDatos = data.redes;
        console.log('redes', scope.redesDatos);
        
        scope.productosDatos = data.productos;
        console.log('productos', scope.productosDatos);      
       
        let newObj = data.servicios.filter((value)=>{
          return value.SerTip.indexOf('portada') != -1 ? value:null
        });
        scope.serviciosDatos = newObj;
        console.log('servicios', scope.serviciosDatos);
        
        scope.sliderDatos = data.slider;
        console.log('slider', scope.sliderDatos);
        
        scope.listaSecciones2 = data.sections;
        console.log('secciones 2', scope.listaSecciones2);
        

        //localStorage.removeItem('myProducts');
        //localStorage.removeItem('refresh');
        //localStorage.setItem('myProducts', JSON.stringify(data.productos));


        let newObj1 = data.sections.filter((value)=>{
          return value.Ord.indexOf('2') != -1 ? value:null
        });
        scope.listaSecciones=newObj1;  
        console.log('secciones 1', scope.listaSecciones);

        let newObj3 = data.sections.filter((x)=>{            
          return x.Url.indexOf(seccion) != -1 ? x:null
        });          

        scope.seccionSola=newObj3[0];        
        console.log('seccion sola', scope.seccionSola);     
        
        /*let galIns = data.galeria[0].GalID;
        let newObj4 = data.fotos.filter((x)=>{
          return x.GalID.indexOf(galIns) != -1 ? x:null
        })

        scope.galInsgram = newObj4;
        console.log('Galeria instagram', scope.galInsgram);*/

      })

    })
  }

  owlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    //navSpeed: 750,
    navText: ['&#8249', '&#8250;'],
    autoplay:true,
    autoplaySpeed:770,
    autoplayHoverPause:true,
    autoplayTimeout:4000,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      767: {
        items: 1
      },
      1024: {
        items: 1
      }
    },
    nav: true
  }

  reserCon(){
    console.log('Hola mundo mundial');
    

  }

}
