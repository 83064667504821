import { Component } from '@angular/core';
import { WebService } from './service/web.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public webDatos:any;
  public seccionDatos:any;
  public redesDatos:any;

  constructor(private webService: WebService){}
  
  ngOnInit():void{

    let scope = this;    
    let data:any=scope.webService.getEmpresa();

    let dom = data[0];
    let web = data[1];
    let org = data[2];

    let miPromesa = new Promise(function(resolve:any, reject:any){
      resolve();
    });

    miPromesa.then(function(){
      scope.webService.getDatos(0,dom, org,web,0,0,0,0,0).subscribe((data)=>{

        scope.webDatos=data.web[0];
        scope.seccionDatos=data.sections;
        scope.seccionDatos.sort((a, b) => a.ConID - b.ConID);
        scope.redesDatos=data.redes;
      })
    })

  }
}
